import { Button, Divider, Row, Col, Space } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { OverPack }  from 'rc-scroll-anim';
import style from './about.module.scss';

const About = () => (
  <OverPack always={false}>
    <div className={style.container}>
      <QueueAnim
        key="about-anim"
        type="left"
        duration={1500}
        interval={500}
        component={Row}
        componentProps={{
          gutter: { xs: 8, sm: 16, md: 24, lg: 32 },
          className: style.wrapper
        }}>
        <Col xs={{offset: 2, span: 20}} md={{offset: 2, span: 20}} key="about-anim-1">
          <h1 className={style.title}>
            <span style={{color: "orange"}}>About</span> Me
          </h1>
        </Col>
        <Col xs={{offset: 2, span: 20}} md={{offset: 2, span: 10}} className={style.desc} key="about-anim-2">
          <h2>
            Hi! My name is Arda Firdaus Ramadhan. I love developing websites. I got some experience in backend development. Sometimes I also work on the client-side. Here are some technologies that I used.
          </h2>
        </Col>
        <Col xs={{offset: 2, span: 20}} md={{offset: 0, span: 10}} key="about-anim-3">
          <QueueAnim
            key="skill-anim"
            type="left"
            duration={1000}
            interval={600}>
            <div key="skill-anim-1">
              <Divider orientation="left"><h2>Frontend</h2></Divider>
              <div className={style.frontend}>
                <Space wrap>
                  <Button type="primary" shape="round">HTML</Button>
                  <Button type="primary" shape="round">CSS</Button>
                  <Button type="primary" shape="round">Javascript</Button>
                  <Button type="primary" shape="round">React</Button>
                  <Button type="primary" shape="round">Redux</Button>
                  <Button type="primary" shape="round">SASS</Button>
                </Space>
              </div>
            </div>
            <div key="skill-anim-2">
              <Divider orientation="right"><h2>Backend</h2></Divider>
              <div className={style.backend}>
                <Space wrap>
                  <Button type="primary" shape="round">Golang</Button>
                  <Button type="primary" shape="round">Node JS</Button>
                  <Button type="primary" shape="round">PHP</Button>
                  {/* <Button type="primary" shape="round">REST</Button>
                  <Button type="primary" shape="round">GraphQL</Button> */}
                </Space>
              </div>
            </div>
            <div key="skill-anim-3">
              <Divider orientation="center"><h2>Other</h2></Divider>
              <div className={style.other}>
                <Space wrap>
                  <Button type="primary" shape="round">SQL</Button>
                  <Button type="primary" shape="round">NoSQL</Button>
                  <Button type="primary" shape="round">Redis</Button>
                  <Button type="primary" shape="round">Docker</Button>
                </Space>
              </div>
            </div>
          </QueueAnim>
        </Col>
      </QueueAnim>
    </div>
  </OverPack>
);

export default About;
