import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import { OverPack } from 'rc-scroll-anim';
import QueueAnim from 'rc-queue-anim';
import style from './profile.module.scss';

import profileImage from '../../../assets/images/profile.jpg'

const Profile = () => (
  <OverPack always={false}>
    <QueueAnim
      key="profile-anim"
      type="bottom"
      interval={600}
      duration={1000}
      component={Row}
      componentProps={{
        justify:"center",
        align:"middle",
        gutter: { xs: 8, sm: 16, md: 24, lg: 32 },
        className: style.container
      }}>
      <Col xs={{offset: 2, span: 20}} md={{span: 5}} className={style.photoContainer} key="profile-anim-1">
        <img src={profileImage} alt="Arda" draggable={false} />
      </Col>
      <Col xs={{offset: 2, span: 20}} md={{span: 10}} key="profile-anim-2">
        <QueueAnim
          key="profile-desc-anim"
          type="right"
          duration={1000}>
          <h3 key="profile-desc-anim-1" className={style.location}>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Malang, Indonesia
          </h3>
          <h1 key="profile-desc-anim-2" className={style.title}>
            Hello,<br/> I'm <span style={{color: "orange"}}>Arda</span> Ramadhan
          </h1>
          <h2 key="profile-desc-anim-3" className={style.desc}>
          Backend Engineer who passionated learning web development from end-to-end. Interested in software architecture and distributed systems.
          </h2>
          <QueueAnim
            key="profile-link-anim"
            type="right"
            duration={1600}
            interval={400}
            component={Row}
            className={style.linkContainer}>
              <Col key="profile-link-anim-0" xs={{span: 12}} lg={{span: 6}}>
                <h3 style={{fontWeight: "bold"}}>
                  <a type="mail" href="mailto:ardafirdausr@gmail.com" target="_blank" rel="noreferrer" className={style.linkIcon}>
                    <FontAwesomeIcon icon={faEnvelope} /> Email
                  </a>
                </h3>
              </Col>
              <Col key="profile-link-anim-1" xs={{span: 12}} lg={{span: 6}}>
                <h3 style={{fontWeight: "bold"}}>
                  <a href="https://linkedin.com/in/ardafirdausr" target="_blank" rel="noreferrer" className={style.linkIcon}>
                    <FontAwesomeIcon icon={faLinkedinIn} /> Linkedin
                  </a>
                </h3>
              </Col>
              <Col key="profile-link-anim-2" xs={{span: 12}} lg={{span: 6}}>
                <h3 style={{fontWeight: "bold"}}>
                  <a href="https://github.com/ardafirdausr" target="_blank" rel="noreferrer" className={style.linkIcon}>
                    <FontAwesomeIcon icon={faGithub} /> Github
                  </a>
                </h3>
              </Col>
              <Col key="profile-link-anim-3" xs={{span: 12}} lg={{span: 6}}>
                <h3 style={{fontWeight: "bold"}}>
                  <a href="https://drive.google.com/file/d/18u8ku95JxcA2JMu8u8UpFv3nMQ1O-wwA/view?usp=sharing" target="_blank" rel="noreferrer" className={style.linkIcon}>
                    <FontAwesomeIcon icon={faFilePdf} /> Resume
                  </a>
                </h3>
              </Col>
          </QueueAnim>
        </QueueAnim>
      </Col>
    </QueueAnim>
  </OverPack>
);

export default Profile;
