import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Home from './views/pages/home/index.js';

const AppRouter = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={Home}/>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  </Router>
)

export default AppRouter;