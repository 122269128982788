import { useState } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  message
} from 'antd';
import QueueAnim from 'rc-queue-anim';
import { OverPack }  from 'rc-scroll-anim';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faFacebook, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import style from './contact.module.scss';

const Mailer = () => {
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)

  const onFinish = async (values) => {
    try {
      setSending(true)
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        values,
        process.env.REACT_APP_EMAILJS_USER_ID)
      setSent(true)
      message.success("Email sent")
    } catch (error) {
      console.log(error)
      message.error("Email failed to send")
    } finally {
      setSending(false)
    }
  };

  return (
    <Form
      name="mailer"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      size={`${window.innerWidth < 768 ? 'middle' : 'large'}`}
      style={{width: "100%"}}>
      <Form.Item
        label="Name"
        name="from_name"
        rules={[{ required: true, message: 'Please input your name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="from_email"
        rules={[
          { required: true, message: 'Please input your email!' },
          { type: 'email', message: 'Invalid email address' }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Message"
        name="message"
        rules={[
          { required: true, message: 'Please input your message!' },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item wrapperCol={{
        xs: {span: 24},
        sm: {offset: 8, span: 16},
      }}>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={sending}
          disabled={sent}
          style={{width: '100%'}}>
          { sent ? 'Email Sent' : 'Submit'}
        </Button>
      </Form.Item>
    </Form>
  );
}

const About = () => (
  <OverPack always={false}>
    <div className={style.container}>
      <QueueAnim
        key="contact-anim"
        type="bottom"
        duration={1000}
        interval={500}
        component={Row}
        componentProps={{
          gutter: { xs: 8, sm: 16, md: 24, lg: 32 },
          className: style.wrapper
        }}>
        <Col xs={{offset: 2, span: 20}} lg={{offset: 2, span: 20}} key="contact-anim-1">
          <h1 className={style.title}>
            <span style={{color: "orange"}}>Contact</span> Me
          </h1>
        </Col>
        <Col xs={{span: 0}} lg={{offset: 2, span: 10}} key="contact-anim-2">
          <h1 className={style.secondTitle}>Connect with me ;)</h1>
          <QueueAnim
            key="social-anim"
            type="left"
            duration={1600}
            interval={400}
            component={Row}
            componentProps={{
              gutter: { xs: 8, sm: 16, md: 24, lg: 32 },
              justify: 'center'
            }}>
              <Col span={6}>
                <h3 className={style.social} key="social-anim-2">
                  <a href="https://linkedin.com/in/ardafirdausr" target="_blank" rel="noreferrer" className={style.linkIcon}>
                    <FontAwesomeIcon icon={faLinkedinIn} /> <br/> Linkedin
                  </a>
                </h3>
              </Col>
              <Col span={6}>
                <h3 className={style.social} key="social-anim-3">
                  <a href="https://twitter.com/ardafirdausr" target="_blank" rel="noreferrer" className={style.linkIcon}>
                    <FontAwesomeIcon icon={faTwitter} /> <br/> Twitter
                  </a>
                </h3>
              </Col>
              <Col span={6}>
                <h3 className={style.social} key="social-anim-4">
                  <a href="https://instagram.com/ardafirdausr" target="_blank" rel="noreferrer" className={style.linkIcon}>
                    <FontAwesomeIcon icon={faInstagram} /> <br/> Instagram
                  </a>
                </h3>
              </Col>
              <Col span={6}>
                <h3 className={style.social} key="social-anim-4">
                  <a href="https://facebook.com/ardafirdausr" target="_blank" rel="noreferrer" className={style.linkIcon}>
                    <FontAwesomeIcon icon={faFacebook} /> <br/> Facebook
                  </a>
                </h3>
              </Col>
          </QueueAnim>
        </Col>
        <Col xs={{offset: 2, span: 20}} lg={{offset: 0, span: 10}} key="contact-anim-3">
          <Mailer />
        </Col>
      </QueueAnim>
    </div>
  </OverPack>
);

export default About;
