import { useState, useEffect, useMemo } from 'react';
import {
  Image,
  Tabs,
  Row,
  Col,
  Typography,
  Space,
  Card,
  Button,
  Empty
} from 'antd';
import QueueAnim from 'rc-queue-anim';
import { OverPack }  from 'rc-scroll-anim';
import { Blurhash } from "react-blurhash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faRocket, faCode } from '@fortawesome/free-solid-svg-icons'

import style from './projects.module.scss';

const { Title } = Typography;
const { TabPane } = Tabs;

const projectData = [
	{
    "name": "Discuss",
    "type": "personal",
		"image": '/assets/images/discuss-chat.png',
		"imageHash": 'o2Ss50~Wt7~X0J9ZD%00H@D%IU9Y9F9F9ZRjInVt%3RjM{X4aKbFWBRQo|%MtQnS?ISc-q%MxbNY',
		"urlDemo": "https://discuss.eksperimen.site",
		"urlCode": ["https://github.com/ardafirdausr/discuss-server", "https://github.com/ardafirdausr/discuss-client"],
		"stacks": ["Golang", "Echo", "Web Socket", "REST API", "PubSub", "Redis", "MongoDB", "React", "Google SSO"],
		"description": "Discuss is web-based chat application built using Golang and React. This App allows users to chat with each other in a discussion room in a real-time.",
    "descriptionPoints": [],
	}, {
    "name": "Pertamina Against Covid-19",
    "type": "freelance",
		"image": '/assets/images/pertamina.png',
		"imageHash": '|PPN3BmQZ$qFL#bvkqZ$i_:Qo}kWVspIaeaKkCbH8_Xmbbick?oLadkDkC=KWBaxofazf6j[aejsE2kWkWaekWaeaekCj[?Hj[aeaya0j[ayj[jZEMoffkWVozWVj]j[ay-paKaekWa0kCbbe.e.OXR*azsoR*ofjZaykC',
		"stacks": ["PHP", "Laravel", "MySQL", "HTML", "CSS", "Javascript"],
		"urlDemo": "http://pac19.pertamina.com/",
		"urlCode": null,
		"description": "Pertamina Against Covid-19 is an application used to control, track, and report covid cases in PT Pertamina.",
    "descriptionPoints": [],
	}, {
    "name": "Simafala",
    "type": "personal",
		"image": '/assets/images/simafala.png',
		"imageHash": 'yRDx6m%KNKE4NgadWE05NKt6$~WEs-f6_3oyWBNHxANejY0PjEj[ozf5ofoe~1n#W=W=bcf5WFERbcWBV@RlodoJT1I[t3$|s*Wrae',
		"urlDemo": "https://simafala.eksperimen.site",
		"urlCode": null,
		"stacks": ["PHP", "Laravel", "MySQL", "HTML", "CSS", "Javascript", "Vue"],
		"description": "Simafala is a web-based application that provides reservation and payment services for laboratory facilities. Simafala makes researchers easier to search and consult laboratory facilities without going through a long process.",
    "descriptionPoints": [],
	}, {
    "name": "UB Tanggap Covid-19",
    "type": "freelance",
		"image": '/assets/images/ub.png',
		"imageHash": 'oCQAHhAG~X54ni-r0y9YITACxG-AAZEK9FEKjF%2v3NZGXviX7TIbERPWD?cS#IA?vsCa1%2S3NH',
		"stacks": ["PHP", "Laravel", "MySQL", "HTML", "CSS", "Javascript", "Flutter"],
		"urlDemo": "http://sc19.ub.ac.id/",
		"urlCode": null,
		"description": "UB Tanggap Covid-19 is web and mobile-based application. UB Tanggap Covid-19 is an application used to controlling and tracking covid symptoms of students and employees at Brawijaya University.",
    "descriptionPoints": [],
	}, {
    "name": "Sambat Kota Malang",
    "type": "freelance",
		"image": '/assets/images/sambat.png',
		"imageHash": '|IR2=BHrel_MX-E2rr$N.8hfS}XTMzsBxtW-bvV@Q8tS$%Z~aKWVN_X7NFL#-WR*kVNaRks9RPjEZ2XRI=IpWAxZs.kCflD4IArpnMoeXTbwtRWpH=x[Nefns:s,oeNGjZDjV[x[x]bvM|VtnhofRjV?snV@WBofWqt7ae',
		"stacks": ["PHP", "Laravel", "MySQL", "HTML", "CSS", "Javascript", "Flutter"],
		"urlDemo": null,
		"urlCode": null,
		"description": "Sambat is a web and mobile-based e-complaint application. This system can be used by the public to submit complaints about problems in Malang.",
    "descriptionPoints": [],
	}, {
    "name": "Singo Pajak Kota Malang",
    "type": "freelance",
		"image": '/assets/images/singo.png',
		"imageHash": '|SL}HR00IX00VYROE1t7xt00kq-;W,$*smNaogWD~qo~D%X6R4V?kWjvkC?ao#IUozROe.ozWBofNHRkn$s.t7s.W=kCRks+RjRkogs;offRWBWB-;oLIUaeWqbIn%WBof%MVsodaeS5WCjZoeWBRinhxuV[S5Wrnij?oe',
		"stacks": ["PHP", "Codeigniter", "Oracle", "HTML", "CSS", "Javascript"],
		"urlDemo": null,
		"urlCode": null,
		"description": "Singo Pajak Malang is a geographic information system that is used to manage and map the status of tax objects in Malang.",
    "descriptionPoints": [],
	}, {
    "name": "Biru Bumi Hijau Company Profile",
    "type": "freelance",
		"image": '/assets/images/bbh.png',
		"imageHash": '|6AJyT~Vw@D$9ZM{WXRjIV%NM|bb%MxujYV@ofoeJqD*R.%M%2j@WBR+s:_N-:n$D*D*R*WXV@M{IUbHt7t7ofjsWBt6a#IVIAWB%2xuj]j@WXof%M%MWBMxRjofoLV@j?IVWBoLj[fkf6t7xaoft7ayj[WBaybIj]WVfQ',
		"stacks": ["PHP", "Laravel", "MySQL", "HTML", "CSS", "Javascript", "Google Analytics"],
		"urlDemo": "https://birubumihijau.co.id/",
		"urlCode": null,
		"description": "Biru Bumi Hijau Company Profile shows the company's information, projects portfolio, and services. The Admin can manage web content and monitor guest traffic.",
    "descriptionPoints": [],
	}, {
    "name": "SiapBos",
    "type": "freelance",
		"image": '/assets/images/siapbos.png',
		"imageHash": '-4SF;MPB^k}Yu44;?F#85QBnrD^j4;AE#l+bPA9b-nv#9aFwm,-o01I;w^#mEzE1?GeSE1JRn4%1NGNGVsi_S#R+~qMxIUR%nhs:',
		"stacks": ["PHP", "Laravel", "MySQL", "HTML", "CSS", "Javascript"],
		"urlDemo": null,
		"urlCode": null,
		"description": "SiapBos is a system that consists of several ERP modules such as purchasing management, point of sales, payroll management and inventory management. ",
    "descriptionPoints": [],
	}, {
    "name": "Kaseer",
    "type": "personal",
		"image": '/assets/images/pos.png',
		"imageHash": 'y3QmI|0000000002E5x_yFx^%ho~o#f+%3N3xcxvIXxvV@_4t8D+Rqaht7WB~To[E0WToZNFslx^WTWSxVoGWTjX-psqRVRoRPxaRj',
		"urlDemo": "https://kaseer.eksperimen.site",
		"urlCode": "https://github.com/ardafirdausr/kaseer",
		"stacks": ["Golang", "Echo", "MongoDB", "HTML", "CSS", "Javascript"],
		"description": "Kaseer is a web-based Point of Sales (POS). Kaseer allows users to manage products, create orders, and monitor the order statistics. This app is developed using clean architecture.",
    "descriptionPoints": [],
	}, {
    "name": "Todo",
    "type": "personal",
		"image": '/assets/images/todo.png',
		"imageHash": ':4S6PmxungtQ-;xv4:xtNfn$-nS$s;jEE2t7RPj;t8WajXoHWEoL~WWTE2s?oxW+xaR*t8R*WTxaNHR%$+WX.8WFV@xUWCRpozoJNYoht8R#RkoOkSof?Zt8N1ImWE%3xoR-',
		"urlDemo": "https://todo.eksperimen.site",
		"urlCode": ["https://github.com/ardafirdausr/todo-server", "https://github.com/ardafirdausr/todo-client"],
		"stacks": ["Golang", "Echo", "MongoDB", "GoogleSSO", "React JS"],
		"description": "A Todo app developed using clean architecture.",
    "descriptionPoints": [],
  }
];

const Project = ({ project }) => {
  const isMobile = window.innerWidth <= 768;
  return (
    <Card hoverable style={{marginBottom: '40px', border: 'none'}}>
      <Row>
        <Col xs={{span: 24}} md={{span: 10}}>
          <Image
            style={{width: "100%"}}
            src={project.image}
            placeholder={
              <Blurhash
                hash={project.imageHash}
                width="100%"
                height="100%"
              />
            }
          />
        </Col>
        <Col xs={{span: 24}} md={{offset: 2, span: 12}}>
          <Title level={3}>{project.name}</Title>
          <h3>{project.description}</h3>
          <h4>
            <ul>
              {
                project.descriptionPoints &&
                project.descriptionPoints.map((point) => (
                  <li>{point}</li>
                ))
              }
            </ul>
          </h4>
          <div>
            {
              project.urlDemo && (
                <Row>
                  <Col flex="24px"><FontAwesomeIcon icon={faRocket} /></Col>
                  <Col flex="1">
                    <h4>
                      <Space>
                        { !isMobile && <b>Demo</b>}
                        <b><a href={project.urlDemo} target="_blank" rel="noreferrer">{project.urlDemo}</a></b>
                      </Space>
                    </h4>
                  </Col>
                </Row>
              )
            }
          </div>
          { !isMobile &&
            <div>
              {
                !project.urlCode ? null : (
                  <Row>
                    <Col flex="24px"><FontAwesomeIcon icon={faCode} /></Col>
                    <Col flex="1">
                      {
                        !Array.isArray(project.urlCode)
                          ? (
                            <h4>
                              <Space>
                                <b>Code</b>
                                <b><a href={project.urlCode} target="_blank" rel="noreferrer">{project.urlCode}</a></b>
                              </Space>
                            </h4>
                          ) : (
                            <div>
                              <h4><b>Code</b></h4>
                              <div style={{marginBottom: "10px"}}>
                                {project.urlCode.map(url => <div><b><a href={url} target="_blank" rel="noreferrer">{url}</a></b></div>)}
                              </div>
                            </div>
                          )
                      }
                    </Col>
                  </Row>
                )
              }
            </div>
          }
          <div>
            <Row>
              <Col flex="24px"><FontAwesomeIcon icon={faLayerGroup} /></Col>
              <Col flex="1">
                <h4><b>Stack</b></h4>
                <div>
                  <Space wrap>
                    {
                      project.stacks &&
                      project.stacks.map((stack) => (
                        <Button
                          type="primary"
                          size="small"
                          shape="round"
                          key={`${project.id}-${stack}`}>
                          {stack}
                        </Button>
                      ))
                    }
                  </Space>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Card>
  )
};

const Projects = () => {
  const [projects, setProjects] = useState([])

  const freelanceCount = useMemo(() => {
    return projects.filter((project) => (project.type === 'freelance')).length
  }, [projects])

  const personalCount = useMemo(() => {
    return projects.filter((project) => (project.type === 'personal')).length
  }, [projects])

  useEffect(() => {
    setProjects(projectData);
  }, [])

  return (
    <OverPack always={false}>
      <div className={style.container}>
        <QueueAnim
          key="projects-anim"
          type="bottom"
          interval={600}
          duration={1000}
          component={Row}
          componentProps={{
            gutter: { xs: 8, sm: 16, md: 24, lg: 32 },
            align: "top",
            justify: "start",
            className: style.wrapper
          }}>
            <Col offset={2} span={20} key="projects-anim-1">
              <h1 className={style.title}>
                <span style={{color: "orange"}}>Project</span> Portfolio
              </h1>
            </Col>
            <Col offset={2} span={20} key="projects-anim-2">
              <Tabs tabPosition="top" size="large" animated centered>
                <TabPane tab="All" key="tab-1">
                  {
                    projects.length < 1
                    ? <Empty style={{marginTop: "100px"}} />
                    : projects.map(project => (
                        <Project project={project} key={`${project.name}-all`} />
                      ))
                  }
                </TabPane>
                <TabPane tab="Freelance" key="tab-2">
                  {
                    freelanceCount < 1
                    ? <Empty style={{marginTop: "100px"}} />
                    : projects.map((project) => {
                        if (project.type !== 'freelance') {
                          return null;
                        }
                        return <Project project={project} key={`${project.name}-freelance`} />
                      })
                  }
                </TabPane>
                <TabPane tab="Personal" key="tab-3">
                    {
                      personalCount < 1
                      ? <Empty style={{marginTop: "100px"}}/>
                      : projects.map((project) => {
                          if (project.type !== 'personal') {
                            return null;
                          }

                          return <Project project={project} key={`${project.name}-personal`} />
                        })
                    }
                  </TabPane>
              </Tabs>
            </Col>
        </QueueAnim>
      </div>
        {/* <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          align="top"
          justify="start"
          className={style.wrapper}>
        </Row> */}
    </OverPack>
  );
}

export default Projects;
