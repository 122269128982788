import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faFacebook, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

import style from './social.module.scss';

const Social = () => (
  <div className={style.container}>
    <Row
      justify="space-around"
      align="middle"
      className={style.wrapper}>
      <Col xs={{span: 6}} lg={{span: 0}} className={style.linkIconWrapper}>
        <a href="https://linkedin.com/in/ardafirdausr" target="_blank" rel="noreferrer" className={style.linkIconFooter}>
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
      </Col>
      <Col xs={{span: 6}} lg={{span: 0}} className={style.linkIconWrapper}>
        <a href="https://twitter.com/ardafirdausr" target="_blank" rel="noreferrer" className={style.linkIconFooter}>
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </Col>
      <Col xs={{span: 6}} lg={{span: 0}} className={style.linkIconWrapper}>
        <a href="https://instagram.com/ardafirdausr" target="_blank" rel="noreferrer" className={style.linkIconFooter}>
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </Col>
      <Col xs={{span: 6}} lg={{span: 0}} className={style.linkIconWrapper}>
        <a href="https://facebook.com/ardafirdausr" target="_blank" rel="noreferrer" className={style.linkIconFooter}>
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      </Col>
    </Row>
  </div>
)

export default Social;