import { Row, Col, Menu } from 'antd';

import style from './navigation-bar.module.scss'

const { Item: MenuItem } = Menu

const NavigationBar = ({ items = [], activeItem = '', onClick }) => (
  <Row justify="end" className={style.container}>
    <Col md={{pull: 2}}>
      <Menu
        mode="horizontal"
        selectedKeys={activeItem}
        onClick={onClick}
        disabledOverflow={true}
        className={style.navigationMenu}>
          {
            items.length &&
            items.map(item => <MenuItem key={item.key}>{item.name}</MenuItem>)
          }
      </Menu>
    </Col>
  </Row>
);

export default NavigationBar;
