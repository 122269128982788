import { useState, useEffect, useRef } from 'react';
import { BackTop, Layout } from 'antd';
import { InView } from 'react-intersection-observer';

import NavigationBar from './navigation-bar';
import Profile from './profile'
import About from './about'
import Projects from './projects'
import Contact from './contact'
import Social from './social'

import style from './index.module.scss';

const { Header, Content } = Layout;

const Home = () => {
  const profile = useRef(null);
  const about = useRef(null);
  const project = useRef(null);
  const contact = useRef(null);

  const navigation = {
    profile: { key: 'profile', name: 'Home', ref: profile },
    about: { key: 'about', name: 'About Me', ref: about },
    project: { key: 'project', name: 'Projects', ref: project },
    contact: { key: 'contact', name: 'Contact Me', ref: contact },
  }
  const navigationItems = Object.values(navigation);

  const scroll = (element) => {
    const offset = 64;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({top: offsetPosition, behavior: 'smooth'});
  }
  const [activeNavigation, setActiveNavigation] = useState('profile');
  const handleChangeNavigation = ({ key }) => {
    scroll(navigation[key].ref.current)
  }

  const [atTop, setAtTop] = useState(true);
  const handleScroll = () => window.addEventListener('scroll', () => setAtTop(window.scrollY < 64));
  useEffect(handleScroll, [])

  return (
    <Layout className={style.layout}>
      <Header className={`${style.header} ${atTop ? style.noShadow : style.shadow}`}>
        <NavigationBar
          items={navigationItems}
          activeItem={activeNavigation}
          onClick={handleChangeNavigation} />
      </Header>
      <Content className={style.content}>
        <div ref={profile}>
          <InView threshold={0.6} onChange={(inView) => inView && setActiveNavigation('profile') }>
            <Profile />
          </InView>
        </div>
        <div ref={about}>
          <InView threshold={0.6} onChange={(inView) => inView && setActiveNavigation('about') }>
            <About />
          </InView>
        </div>
        <div ref={project}>
          <InView threshold={0.09} onChange={(inView) => inView && setActiveNavigation('project') }>
            <Projects />
          </InView>
        </div>
        <div ref={contact}>
          <InView threshold={0.6} onChange={(inView) => inView && setActiveNavigation('contact') }>
            <Contact />
          </InView>
        </div>
        {window.innerWidth < 768 && <BackTop />}
      </Content>
      {window.innerWidth < 992 && <Social />}
    </Layout>
  );
}

export default Home;
